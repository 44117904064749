<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer app color="blue-grey-darken-4">
      <v-row justify="center" no-gutters>
        <v-col class="text-center my-font">
          کلیه حقوق برای شرکت صدراتک محفوظ است. ۱۴۰۲
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style>
@import "@/assets/css/fontiran.css";
.my-font{
  font-family: KalamehWeb;
}
</style>
