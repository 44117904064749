<template>
    <UnderConstruction/>
</template>

<script>
import {defineComponent} from 'vue';

// Components
import UnderConstruction from '../components/UnderConstruction.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    UnderConstruction,
  },
});
</script>