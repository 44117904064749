<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
            :src="require('../assets/site-under-construction.svg')"
            class="my-3"
            contain
            height="400"
        />
      </v-col>

      <v-col class="mb-4 pa-16">
        <h1 class="display-2 my-font font-weight-bold mb-3 ">
          صدرا تک - پیشرو در توسعه دانش‌بنیان
        </h1>
        <h2 class="font-weight-regular my-font">
          به زودی...
        </h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'UnderConstruction',

  data: () => ({}),
}
</script>

<style>
@import "@/assets/css/fontiran.css";

.my-font {
  font-family: KalamehWeb;
}
</style>
